import http from '@/utils/http'

export default {
  getPhones(customer_id) {
    return new Promise((resolve, reject) => {
      http
        .get(`phones`, {
          params: { attribute: 'customer_id', value: customer_id },
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getRooms(customer_id, phone_id, filter) {
    return new Promise((resolve, reject) => {
      http
        .get(`rooms/filtered_from_customer`, {
          params: {
            customer_id: customer_id,
            phone_id: phone_id,
            filter: filter,
          },
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  sendMessage(room_id, text, user_name, user_id, private_public, mediaType) {
    let data = new FormData()
    switch (mediaType) {
      case 'image':
        data.append('message[media]', text)
        data.append('message[message_type]', 'image')
        break
      case 'audio':
        data.append('message[media]', text)
        data.append('message[message_type]', 'audio')
        break
      case 'document':
        data.append('message[media]', text)
        data.append('message[message_type]', 'document')
        break
      default:
        data.append('message[text]', text)
        data.append('message[message_type]', 'text')
        break
    }
    data.append('message[user_name]', user_name)
    data.append('message[user_id]', user_id)
    data.append('message[private]', private_public)
    return new Promise((resolve, reject) => {
      http
        .post(`rooms/${room_id}/messages`, data)
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  closeRoom(room_id, closed) {
    return new Promise((resolve, reject) => {
      http
        .post(`rooms/close_room`, {
          room_id: room_id,
          closed_room: closed,
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  toggleChatbot(room_id, active_deactive) {
    return new Promise((resolve, reject) => {
      http
        .post(`rooms/${room_id}/toggle_chatbot`, {
          active: active_deactive,
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
}
