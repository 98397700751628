<template>
  <div v-if="button.web_option">
    <button>{{ button.text }}</button>
  </div>
  <div v-else>
    <div v-if="button.phone_url">
      <small>Phone: {{ button.text }}</small>
    </div>
    <div v-else>
      <small>URL: {{ button.text }}</small>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MessageViewBody',
  props: {
    button: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  created() {},
  computed: {},
}
</script>

<style></style>
