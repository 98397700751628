<template>
  <div>
    <video width="320" height="240" controls>
      <source :src="message.media_url" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>
<script>
export default {
  name: 'MessageViewBody',
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style>
.message {
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px 0px;
  background: #202c33;
  margin: 5px 100px;
  padding: 5px 10px;
  border-radius: 5px;
  width: fit-content;
  color: white;
}
.message.send {
  background: #005c4b;
  align-self: flex-end;
  color: white;
}
.message span {
  margin-left: 25px;
  font-size: 11px;
  color: rgb(201, 201, 201);
}
.status-failed {
  color: rgb(220, 6, 6);
}
.status-read {
  color: #53bdeb;
}
</style>
