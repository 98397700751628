<template>
  <div>
    <button
      type="button"
      class="btn contact-btn"
      @click="showContactsModal(message.contacts)"
    >
      {{ setTitle(message.contacts) }} <br />
      <hr />
      Ver contacto{{ message.contacts.length > 1 ? 's' : '' }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'MessageViewBody',
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      showContact: true,
    }
  },
  methods: {
    setTitle(contacts) {
      let title = JSON.parse(this.message.contacts[0]).name.formatted_name
      title +=
        contacts.length > 1
          ? ` y ${contacts.length - 1} contacto${
              contacts.length - 1 > 1 ? 's' : ''
            } más`
          : ''
      return title
    },
    showContactsModal(contacts) {
      this.$emit('show-contacts-modal', contacts)
    },
  },
}
</script>

<style></style>
