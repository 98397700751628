<template>
  <div>
    <MessageText v-if="message.header == 'text'" :message="message" />
    <MessageDocument v-if="message.header == 'document'" :message="message" />
    <div>{{ message.text }}</div>
    <div>
      <small>{{ message.footer }}</small>
    </div>
    <div v-if="message.template">
      <div v-if="message.template.buttons">
        <div v-for="button in message.template.buttons" :key="button.id">
          <TemplateButtons :button="button"></TemplateButtons>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MessageText from './Text.vue'
import MessageDocument from './Document.vue'
import TemplateButtons from './Templates/Buttons.vue'

export default {
  name: 'MessageViewBody',
  components: {
    MessageText,
    MessageDocument,
    TemplateButtons,
  },
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style></style>
