import http from '@/utils/http'

export default {
  getMessages(slug, page) {
    return new Promise((resolve, reject) => {
      http
        .get(`rooms/${slug}/messages?page=${page}&size=30`)
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
}
