<template>
  <div>
    <button
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      @click="showContactModal = true"
    >
      Ingresar Contacto
    </button>
    <div v-if="showContactModal">
      <transition name="modal">
        <div class="modal-mask" @click="showProgramResponse = false">
          <div class="modal-wrapper">
            <div class="modal-container" @click.stop="">
              <div class="modal-header">
                Programar mensaje
                <button
                  class="modal-default-button"
                  @click="showProgramResponse = false"
                >
                  &#x2715;
                </button>
              </div>
              <div class="modal-body">
                <p>
                  Mensaje programado correctamente.
                </p>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-block btn-primary"
                  @click="showProgramResponse = false"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    userName: { type: String, default: null },
    lastMessage: { type: String, default: null },
    activeMessage: { type: Boolean, default: null },
    userPhoto: { type: String, default: null },
    lastMessageTime: { type: String, default: null },
  },
  data() {
    return {
      showContactModal: false,
    }
  },
}
</script>
