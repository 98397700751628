import http from '@/utils/http';

export default {
  getExecutiveMessages(user_id, message = ''){
    return new Promise(((resolve, reject) => {
      http.get(`quick-responses?by_user=${user_id}&search=${message}`)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err))
    }))
  },
  getCustomerMessages(customer_id, message = ''){
    return new Promise(((resolve, reject) => {
      http.get(`quick-responses?by_customer=${customer_id}&search=${message}`)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err))
    }))
  },
  getAllMessages(user_id, customer_id, message = ''){
    return new Promise(((resolve, reject) => {
      http.get(`quick-responses?by_user=${user_id}&by_customer=${customer_id}&search=${message}`)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err))
    }))
  },
  addMessage(user_id, customer_id, message){
    return new Promise(((resolve, reject) => {
      http.post('quick-responses', {user_id, customer_id, category_quick_response_id: 2, text: message})
        .then((response) => resolve(response.data))
        .catch((err) => reject(err))
    }))
  },
  editMessage(id, user_id, customer_id, message){
    return new Promise(((resolve, reject) => {
      http.put(`quick-responses/${id}`, {user_id, customer_id, category_quick_response_id: 2, text: message})
        .then((response) => resolve(response.data))
        .catch((err) => reject(err))
    }))
  },
  deleteMessage(id){
    return new Promise(((resolve, reject) => {
      http.delete(`quick-responses/${id}`)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err))
    }))
  }
}
