<template>
  <div><b>Respuesta:</b> {{ message.text }}</div>
</template>
<script>
export default {
  name: 'MessageViewBody',
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>
