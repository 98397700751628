<template>
  <div>
    <a
      :href="message.media_url"
      target="_blank"
      class="message-type-button w-auto px-2"
    >
      Descargar</a
    >
  </div>
</template>
<script>
export default {
  name: 'MessageViewBody',
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style></style>
