<template>
  <div>
    <a
      :href="
        'https://maps.google.com/?q=' +
          message.latitude +
          ',' +
          message.longitude
      "
      target="_blank"
      ><img
        class="img-responsive"
        src="https://s3.amazonaws.com/cdn.mobysuite.com/extras/location-difuminated.jpg"
    /></a>
  </div>
</template>
<script>
export default {
  name: 'MessageViewBody',
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style></style>
