<template>
  <div>
    <audio controls>
      <source :src="message.media_url" type="audio/ogg" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style></style>
