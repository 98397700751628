<template>
  <div>
    <img
      :src="message.media_url"
      class="img-responsive"
      @click="showImage(message.media_url)"
    />
    {{ message.text }}
  </div>
</template>
<script>
export default {
  name: 'MessageViewBody',
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    showImage(url) {
      this.$emit('show-image', url)
    },
  },
}
</script>

<style></style>
